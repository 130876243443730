// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Grid, Link, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import SchoolIcon from '@mui/icons-material/School';

// project imports
import { gridSpacing } from 'store/constant';
import LogoFooter from 'ui-component/LogoFooter';

// assets
import { AcademyIcon } from 'ui-component/Icons';

// styles
const FooterLink = styled(Link)(({ theme }) => ({
    color: `${theme.palette.text.primary} !important`,
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none !important',
    '& svg': {
        fontsize: '1.125rem',
        marginRight: 8
    },
    '&:hover': {
        opacity: 0.8
    }
}));

const FooterCard = styled(Card)(({ theme }) => ({
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    border: '1px solid',
    borderBottom: 0,
    borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75,
    [theme.breakpoints.down('md')]: {
        textAlign: 'center'
    }

}));

const FooterSubCard = styled(Card)(({ theme }) => ({
    padding: 0,
    margin: 0,
    borderRadius: 0,
    border: '1px solid',
    borderTop: 0,
    borderBottom: 0,
    borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75,
    background:  theme.palette.mode === 'dark' ? '#1a223f' : '#f7f7f7',
    [theme.breakpoints.down('md')]: {
        textAlign: 'center'
    }
}));

// ==============================|| LANDING - FOOTER PAGE ||============================== //

const FooterPage = () => {
    const theme = useTheme();

    const getYear = () => {
        const currentYear = new Date().getFullYear();
        return currentYear;
    };

    return (
        <>
            <FooterCard>
                <CardContent>
                    <Grid container alignItems="center" spacing={gridSpacing}>
                        <Grid item xs={12} sm={12} md={4}>
                            <Link href="https://www.drugagency.cz" target="_blank" underline="hover"><LogoFooter /></Link>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <Grid
                                container
                                alignItems="center"
                                spacing={2}
                                sx={{ justifyContent: 'flex-end', [theme.breakpoints.down('md')]: { justifyContent: 'center' } }}
                            >
                                <Grid item>
                                    <FooterLink href="https://www.interakcniakademie.cz" target="_blank" underline="hover">
                                        <AcademyIcon />
                                        Interakční akademie
                                    </FooterLink>
                                </Grid>
                                <Grid item>
                                    <FooterLink href="https://www.lekovaakademie.cz" target="_blank" underline="hover">
                                        <SchoolIcon />
                                        Léková akademie
                                    </FooterLink>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </FooterCard>
            <FooterSubCard>
                <Typography sx={{ color: theme.palette.text.primary, padding: '0.75rem 1.5rem 0.75rem 1.5rem' }} variant="subtitle2" component="div" >
                    DrugAgency a.s. © {getYear()} | Všechna práva vyhrazena
                </Typography>
            </FooterSubCard>
        </>
    );
};

export default FooterPage;
