import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';

export function HeartIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 20 18">
            <path d="m10.01003,2.191c-4.6,-5 -12.08,-0.775 -9.47,5.979c0.07,0.185 0.15,0.368 0.23,0.548l3.42,0l2.17,-4.881l2.53,6.751l0.71,-1.87l9.65,0c0.08,-0.18 0.16,-0.363 0.23,-0.548c2.61,-6.754 -4.87,-10.979 -9.47,-5.979zm0.49,7.839l-1.61,4.264l-2.64,-7.01l-1.21,2.746l-3.63,0a20.529,20.529 0 0 0 8.6,7.971a20.529,20.529 0 0 0 8.6,-7.971l-8.11,0z" />
        </SvgIcon>
    );
}

export function ElderlyIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 12 18">
            <path d="M8.4,18l-1.1,0c-0.4,0-0.8-0.3-0.8-0.8V9.6l-2.5,1.1c-0.3,0.3-0.7,0.4-1.1,0.5l-0.5,0.1l0,0.1v5.9
                C2.3,17.7,2,18,1.6,18c0,0,0,0,0,0l0,0h0c-0.4,0-0.8-0.3-0.8-0.7c0,0,0,0,0,0v-5.8l0-0.3c-0.1,0-0.2-0.1-0.3-0.2
                C0.2,10.7,0,10.4,0,10c0-0.5,0.3-1,0.8-1.2L3,7.7c0.1-0.1,0.3-0.2,0.4-0.3l0.4-0.6c0.7-1,2.8-1.5,4-1.5h0.9c0.5,0,1.1,0.2,1.4,0.5
                c1.3,1.1,1.9,3.1,1.9,6.1c0,0.9-0.2,1.7-0.7,2.4L11,14.7c-0.3,0.5-0.5,1.1-0.5,1.7v1.1c0,0.3-0.3,0.6-0.6,0.6C9.9,18,8.4,18,8.4,18z
                    M7.3,4.5C6,4.4,5.1,3.4,5.1,2.2C5.2,0.9,6.2-0.1,7.5,0c1.2,0.1,2.2,1,2.2,2.3C9.6,3.5,8.5,4.5,7.3,4.5L7.3,4.5" />
        </SvgIcon>
    );
}

export function InfoIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 6 14">
            <path d="m5.61,12.5l-0.99,0l0,-6.884a0.375,0.375 0 0 0 -0.38,-0.37l-3.85,0a0.378,0.378 0 0 0 -0.39,0.37l0,1.24a0.378,0.378 0 0 0 0.39,0.371l1.09,0l0,5.273l-1,0a0.375,0.375 0 0 0 -0.38,0.371l0,0.759a0.375,0.375 0 0 0 0.38,0.371l5.13,0a0.378,0.378 0 0 0 0.39,-0.371l0,-0.76a0.378,0.378 0 0 0 -0.39,-0.37zm-2.61,-8.156a2.173,2.173 0 1 0 -2.26,-2.171a2.217,2.217 0 0 0 2.26,2.17l0,0.001z" />
        </SvgIcon>
    );
}

export function PLIIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 18 14">
            <path d="m4.4,6.569l-4.22,3.228a0.572,0.572 0 0 0 0,0.86l4.22,3.227a0.524,0.524 0 0 0 0.84,-0.43l0,-1.614l10.02,0a0.535,0.535 0 0 0 0.53,-0.537l0,-2.152a0.53,0.53 0 0 0 -0.53,-0.531l-10.02,0l0,-1.62a0.524,0.524 0 0 0 -0.84,-0.431zm8.31,-6.024l0,1.621l-10.02,0a0.529,0.529 0 0 0 -0.53,0.53l0,2.152a0.536,0.536 0 0 0 0.53,0.538l10.02,0l0,1.614a0.524,0.524 0 0 0 0.84,0.431l4.22,-3.228a0.545,0.545 0 0 0 0,-0.86l-4.22,-3.227a0.524,0.524 0 0 0 -0.84,0.429z" />
        </SvgIcon>
    );
}

export function BookIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 16 14">
            <path d="m15.992,3.749l-0.2,-1.045a0.307,0.307 0 0 0 -0.3,-0.247l0,0a0.306,0.306 0 0 0 -0.3,0.25l-1.765,9.9l-10,0a0.761,0.761 0 0 1 -0.562,-0.245a0.918,0.918 0 0 1 -0.24,-0.682a1.026,1.026 0 0 1 0.983,-0.962l8.507,0a0.307,0.307 0 0 0 0.305,-0.251l1.758,-10.121a0.3,0.3 0 0 0 -0.069,-0.245a0.312,0.312 0 0 0 -0.236,-0.107l-8.5,0a3.961,3.961 0 0 0 -3.725,3.021l-1.6,7.644a2.851,2.851 0 0 0 0.631,2.37a2.9,2.9 0 0 0 2.232,0.964l10.882,0a0.708,0.708 0 0 0 0.7,-0.581l1.5,-9.57a0.3,0.3 0 0 0 -0.001,-0.093z" />
        </SvgIcon>
    );
}

export function AcademyIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 242 213">
            <path d="m60.32,118.85883c-10.48,23.40997 -6.79,51.50997 11,71.49997l78.01,-77.46997c-8.25,-0.49 -17.44,-0.74 -27.12,-0.69c-29.02,0.15 -53.59,2.94 -61.89,6.66zm17.9,78.23997c26.37,22.3 66.1,21.17 91.24,-3.55c20.74,-20.4 25.41,-50.81 13.88,-75.65997c-4.23,-1.61 -11.67,-2.98 -21.25,-3.98l-83.87,83.18997z" />
            <path d="m211.18,139.6088l12.79,1.83c0,0 -2.38,-27.77997 -2.43,-27.75997c-3.66,1.1 -6.06,0.83 -7.47,0.37c-0.05,-0.02 -2.89,25.55997 -2.89,25.55997zm-18.87,-57.90997c-4.88,-5.11 -22.3,-21.58 -71.34,-21.3c-49.08,0.23 -66.26,16.89 -71.05,22.05l-0.05,-6.88c0,0 13.96,-22.16 71.1,-22.45c57.1,-0.32 71.3,21.7 71.3,21.7l0.04,6.88zm-134.37,32.96c-1.06,-0.65 -1.62,-1.33 -1.63,-2.03c-0.04,-5.02 29.03,-9.25 64.9,-9.43c35.93,-0.19 65,3.75 65.04,8.77c0,0.56 -0.35,1.1 -1.03,1.63c1.71,1.04 3.35,2.14 4.93,3.31c2.81,-1.58 4.33,-3.27 4.32,-5.03c0,-0.7 -0.37,-1.41 -0.89,-2.11l0.47,0.23l0,-1.31l-0.09,-16.57l20.94,-13.04l0.14,20.5c-2.3,0.99 -3.94,3.24 -3.89,5.92c0,3.47 2.81,6.29 6.34,6.29c3.52,-0.05 6.34,-2.87 6.29,-6.34c0,-2.68 -1.6,-4.93 -3.9,-5.91l-0.14,-23.14l22.26,-12.44l-121.35,-63.96l-120.65,65.23l48.37,27.74l0.09,16.52l0,1.31l0.47,-0.23c-0.52,0.7 -0.84,1.4 -0.84,2.11c0.01,1.9 1.83,3.72 5.13,5.37c1.5,-1.19 3.08,-2.33 4.72,-3.39z" />
        </SvgIcon>
    );
}

export function RotateToLandscape(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 374.633 390.902">
            <path d="M346.875,200.844H217.707v27.703l129.273,0.055l-0.062,134.652h-0.043l-129.172-0.051
                c-0.004,10.137-2.949,19.516-7.734,27.699h136.906c15.309,0,27.758-12.445,27.758-27.757V228.602
                C374.633,213.309,362.184,200.844,346.875,200.844L346.875,200.844z M162.305,80.543H27.758C12.457,80.543,0,92.996,0,108.301
                v254.844c0,15.312,12.457,27.757,27.758,27.757h134.547c15.3,0,27.754-12.445,27.754-27.757V108.301
                C190.059,92.996,177.605,80.543,162.305,80.543L162.305,80.543z M27.758,108.187l134.652,0.114l-0.058,227.176H27.664
                L27.758,108.187z M80.371,362.414c0-8.09,6.563-14.652,14.645-14.652c8.117,0,14.679,6.562,14.679,14.652
                c0,8.098-6.562,14.672-14.679,14.672C86.934,377.086,80.371,370.512,80.371,362.414L80.371,362.414z M281.266,102.809h-18.442
                c-1.34,0-2.539,0.8-3.054,2.046c-0.504,1.223-0.223,2.649,0.718,3.586l32.36,32.364c1.293,1.281,3.379,1.281,4.672,0l32.355-32.364
                c0.937-0.937,1.227-2.363,0.723-3.586c-0.512-1.246-1.715-2.046-3.055-2.046H308.91C307.863,45.953,261.363,0,204.266,0
                c-7.633,0-13.817,6.187-13.817,13.82c0,7.637,6.184,13.832,13.817,13.832C246.125,27.652,280.227,61.195,281.266,102.809
                L281.266,102.809z"/>
        </SvgIcon>
    );
}

export function Email(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 18.003 12.007">
            <path d="M11.934,6.093l6.069,3.722V2.214L11.934,6.093z M6.069,6.093L0,9.815V2.214L6.069,6.093z
                M17.969,0.929L9.002,6.661L0.034,0.929C0.118,0.406,0.564,0,1.125,0h15.753C17.44,0,17.885,0.406,17.969,0.929z M17.967,11.086
                c-0.087,0.519-0.53,0.921-1.089,0.921H1.125c-0.559,0-1.003-0.403-1.089-0.921L7.1,6.751L8.692,7.77
                c0.094,0.06,0.201,0.089,0.309,0.089c0.108,0,0.215-0.029,0.309-0.089l1.592-1.017L17.967,11.086z"/>
        </SvgIcon>
    );
}

export function Visa(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 35.96 22.91" >
            <rect fill="#fff" x="0.38" y="0.38" width="35.21" height="22.16" rx="1.86" />
            <path fill="#333" d="M33.73.75a1.48,1.48,0,0,1,1.48,1.48V20.68a1.48,1.48,0,0,1-1.48,1.48H2.23A1.48,1.48,0,0,1,.75,20.68V2.23A1.48,1.48,0,0,1,2.23.75h31.5m0-.75H2.23A2.23,2.23,0,0,0,0,2.23V20.68a2.23,2.23,0,0,0,2.23,2.23h31.5A2.23,2.23,0,0,0,36,20.68V2.23A2.23,2.23,0,0,0,33.73,0Z" />
            <path id="VISA" fill="url(#gradient)" d="M18.58,9.84c0,1.25,1.11,1.94,2,2.36s1.16.69,1.16,1.07c0,.58-.69.83-1.34.85a4.71,4.71,0,0,1-2.29-.54l-.4,1.89a7,7,0,0,0,2.49.46c2.34,0,3.88-1.16,3.89-3,0-2.28-3.16-2.4-3.14-3.42,0-.32.31-.64.95-.73a4.21,4.21,0,0,1,2.21.39l.38-1.84A6,6,0,0,0,22.36,7c-2.21,0-3.76,1.18-3.78,2.86Zm11.56-2.7H28.23a1,1,0,0,0-.95.63l-3.35,8h2.35l.46-1.29h2.87l.27,1.29H32L30.14,7.14Zm-2.76,5.59,1.17-3.25.68,3.25ZM15.74,7.14l-1.85,8.65h2.24L18,7.14Zm-3.31,0L10.11,13,9.17,8a1.05,1.05,0,0,0-1-.88H4.32l-.05.25a9.42,9.42,0,0,1,2.21.74A1,1,0,0,1,7,8.89l1.78,6.9h2.36l3.63-8.65Z" />
            <defs>
                <linearGradient id="gradient" x1="4.27" y1="11.46" x2="31.95" y2="11.46" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#272e62" />
                    <stop offset="1" stopColor="#294999" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}

export function MasterCard(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 35.96 22.91">
            <rect fill="#fff" x="0.38" y="0.38" width="35.21" height="22.16" rx="1.86" />
            <path fill="#333" d="M33.73.75a1.48,1.48,0,0,1,1.48,1.48V20.68a1.48,1.48,0,0,1-1.48,1.48H2.23A1.48,1.48,0,0,1,.75,20.68V2.23A1.48,1.48,0,0,1,2.23.75h31.5m0-.75H2.23A2.23,2.23,0,0,0,0,2.23V20.68a2.23,2.23,0,0,0,2.23,2.23h31.5A2.23,2.23,0,0,0,36,20.68V2.23A2.23,2.23,0,0,0,33.73,0Z" />
            <path fill="#eb611c" d="M21.47,11.47a8.62,8.62,0,0,1-3.29,6.78A8.64,8.64,0,0,1,16.74,6.12a8.53,8.53,0,0,1,1.44-1.44A8.61,8.61,0,0,1,21.47,11.47Z" />
            <path fill="#e41221" d="M18.19,18.23h0a8.61,8.61,0,1,1,0-13.55,8.6,8.6,0,0,0,0,13.55Z" />
            <path fill="#f59e1e" d="M32.14,11.47a8.63,8.63,0,0,1-13.95,6.78,8.62,8.62,0,0,0,1.47-12.1,8.52,8.52,0,0,0-1.47-1.47,8.64,8.64,0,0,1,14,6.79Z" />
        </SvgIcon>
    );
}

export function GooglePay(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 35.96 22.91">
            <rect fill="#fff" x="0.38" y="0.38" width="35.21" height="22.16" rx="1.86" />
            <path fill="#333" d="M33.73.75a1.48,1.48,0,0,1,1.48,1.48V20.68a1.48,1.48,0,0,1-1.48,1.48H2.23A1.48,1.48,0,0,1,.75,20.68V2.23A1.48,1.48,0,0,1,2.23.75h31.5m0-.75H2.23A2.23,2.23,0,0,0,0,2.23V20.68a2.23,2.23,0,0,0,2.23,2.23h31.5A2.23,2.23,0,0,0,36,20.68V2.23A2.23,2.23,0,0,0,33.73,0Z" />
            <path fill="#3c4043" d="M17.31,12v3.27h-1V7.19H19a2.41,2.41,0,0,1,1.87,4l-.11.11A2.42,2.42,0,0,1,19,12Zm0-3.81V11H19a1.37,1.37,0,0,0,1-.42,1.43,1.43,0,0,0,0-2,1.38,1.38,0,0,0-1-.4Zm6.56,1.38a2.53,2.53,0,0,1,1.8.61,2.18,2.18,0,0,1,.65,1.69v3.4h-1v-.77h-.05a2,2,0,0,1-1.69.95,2.18,2.18,0,0,1-1.51-.54,1.7,1.7,0,0,1-.6-1.36,1.65,1.65,0,0,1,.64-1.36,2.7,2.7,0,0,1,1.71-.51,3,3,0,0,1,1.5.34v-.24a1.21,1.21,0,0,0-.42-.92,1.44,1.44,0,0,0-1-.38,1.56,1.56,0,0,0-1.36.73l-.91-.57A2.5,2.5,0,0,1,23.87,9.56Zm-1.33,4a.81.81,0,0,0,.34.67,1.23,1.23,0,0,0,.8.27A1.6,1.6,0,0,0,24.83,14a1.5,1.5,0,0,0,.51-1.14A2.06,2.06,0,0,0,24,12.51a1.76,1.76,0,0,0-1,.3.93.93,0,0,0-.42.76Z" />
            <path fill="#3c4043" d="M32,9.73l-3.43,8H27.47l1.27-2.78L26.49,9.73h1.12l1.62,4h0l1.59-4Z" />
            <path fill="#517bbd" d="M13.16,11.28a5.86,5.86,0,0,0-.07-.94H8.76v1.78h2.48a2.14,2.14,0,0,1-.92,1.41v1.16H11.8a4.53,4.53,0,0,0,1.36-3.41Z" />
            <path fill="#31a853" d="M8.76,15.81a4.36,4.36,0,0,0,3-1.12l-1.48-1.16a2.76,2.76,0,0,1-3.82-.81,2.83,2.83,0,0,1-.31-.66H4.67v1.2a4.56,4.56,0,0,0,4.1,2.55Z" />
            <path fill="#fbbc08" d="M6.19,12.06a2.82,2.82,0,0,1,0-1.77V9.1H4.66a4.68,4.68,0,0,0,0,4.16l1.53-1.19Z" />
            <path fill="#e84336" d="M8.76,8.38a2.4,2.4,0,0,1,1.76.69l1.31-1.32a4.34,4.34,0,0,0-3.07-1.2A4.57,4.57,0,0,0,4.66,9.1l1.52,1.2A2.77,2.77,0,0,1,8.76,8.38Z" />
        </SvgIcon>
    );
}

export function ApplePay(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 35.96 22.91">
            <rect fill="#fff" x="0.38" y="0.37" width="35.21" height="22.16" rx="1.86" />
            <path fill="#333" d="M33.73.75a1.48,1.48,0,0,1,1.48,1.48V20.68a1.48,1.48,0,0,1-1.48,1.48H2.23A1.48,1.48,0,0,1,.75,20.68V2.23A1.48,1.48,0,0,1,2.23.75h31.5m0-.75H2.23A2.23,2.23,0,0,0,0,2.23V20.68a2.23,2.23,0,0,0,2.23,2.23h31.5A2.23,2.23,0,0,0,36,20.68V2.23A2.23,2.23,0,0,0,33.73,0Z" />
            <path fill="#000" d="M9.81,7.7a2,2,0,0,0,.46-1.42A2,2,0,0,0,9,7a1.9,1.9,0,0,0-.47,1.37A1.64,1.64,0,0,0,9.81,7.7Zm.46.73c-.74,0-1.36.41-1.72.41s-.89-.39-1.47-.38A2.16,2.16,0,0,0,5.24,9.57c-.79,1.36-.21,3.37.56,4.48.37.54.82,1.15,1.42,1.13s.78-.37,1.46-.37.87.36,1.47.35,1-.54,1.37-1.09a4.89,4.89,0,0,0,.61-1.26,2,2,0,0,1-1.2-1.8,2,2,0,0,1,1-1.7,2.1,2.1,0,0,0-1.64-.89Zm6.4-1.53a2.59,2.59,0,0,1,2.71,2.44V9.6a2.61,2.61,0,0,1-2.5,2.71h-2v2.8H13.58V6.91h3.09Zm-1.81,4.33h1.46a1.54,1.54,0,0,0,1.74-1.31,1.69,1.69,0,0,0,0-.32A1.53,1.53,0,0,0,16.65,8a1.15,1.15,0,0,0-.32,0H14.86v3.25Zm4.86,2.18c0-1.05.8-1.69,2.23-1.77l1.64-.1v-.46c0-.67-.45-1.07-1.2-1.07a1.15,1.15,0,0,0-1.26.88H20C20,9.81,21,9,22.44,9s2.38.77,2.38,2V15.1H23.64v-1h0a2.15,2.15,0,0,1-1.9,1.09,1.82,1.82,0,0,1-2-1.63c0-.06,0-.12,0-.17Zm3.87-.54v-.48l-1.48.1c-.73,0-1.15.37-1.15.88s.44.87,1.1.87a1.43,1.43,0,0,0,1.53-1.34v0Zm2.35,4.44v-1a1.76,1.76,0,0,0,.4,0,1,1,0,0,0,1.06-.85l.11-.37-2.17-6h1.34L28.2,14h0l1.52-4.87H31l-2.25,6.3c-.51,1.45-1.11,1.91-2.35,1.91a4.19,4.19,0,0,1-.5,0Z" />
        </SvgIcon>
    );
}

export function Bank(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 35.96 22.91">
            <rect fill="#fff" x="0.38" y="0.38" width="35.21" height="22.16" rx="1.86" />
            <path fill="#333" d="M33.73.75a1.48,1.48,0,0,1,1.48,1.48V20.68a1.48,1.48,0,0,1-1.48,1.48H2.23A1.48,1.48,0,0,1,.75,20.68V2.23A1.48,1.48,0,0,1,2.23.75h31.5m0-.75H2.23A2.23,2.23,0,0,0,0,2.23H0V20.68a2.23,2.23,0,0,0,2.23,2.23h31.5A2.23,2.23,0,0,0,36,20.72V2.23A2.23,2.23,0,0,0,33.77,0Z" />
            <path fill="#212946" d="M24.94,10.88H11.14a.48.48,0,0,1-.5-.45V9.88h14.8v.5A.55.55,0,0,1,24.94,10.88ZM9.44,8.48a.48.48,0,0,1,.2-.6l8.1-5a.42.42,0,0,1,.5,0l8.1,5a.43.43,0,0,1,.2.59h0a.68.68,0,0,1-.5.4H9.94A.73.73,0,0,1,9.44,8.48Zm7.7-2.2a.47.47,0,0,0,.44.5h.76a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5h-.7A.53.53,0,0,0,17.14,6.28Zm7.6,10.4v-4.8h-2v4.8Zm-3.8,0v-4.8h-2v4.8Zm-7.6,0v-4.8h-2v4.8Zm12.8,1H9.94a.46.46,0,0,0-.5.44v1.36a.47.47,0,0,0,.44.5H26.14a.49.49,0,0,0,.5-.45V18.18a.47.47,0,0,0-.44-.5Zm-9-1v-4.8h-2v4.8Z" />
        </SvgIcon>
    );
}

export function Attention(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 18 16">
            <path fill="#fff" d="M17.8,13.5L10.4,0.9c-0.5-0.8-1.5-1-2.2-0.5C7.9,0.5,7.7,0.7,7.6,0.9L0.2,13.5 c-0.3,0.5-0.3,1.1,0,1.6c0.3,0.5,0.8,0.8,1.4,0.8h14.8c0.6,0,1.1-0.3,1.4-0.8C18.1,14.6,18.1,14,17.8,13.5z M9,13.7 c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6C9.6,13.4,9.4,13.7,9,13.7C9,13.7,9,13.7,9,13.7L9,13.7z M10.1,5.9l-0.8,5.2c0,0.2-0.2,0.3-0.4,0.3c-0.1,0-0.3-0.1-0.3-0.3L7.9,5.9c0-0.3,0-0.5,0.2-0.8C8.3,5,8.6,4.9,8.8,4.9h0.4 c0.3,0,0.5,0.1,0.7,0.3C10.1,5.4,10.2,5.7,10.1,5.9L10.1,5.9z"/>
        </SvgIcon>
    );
}

export function CE(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 41.86 29.63">
            <path d="M30.15,12.55h0A10.49,10.49,0,0,1,40.38,4.36a12.13,12.13,0,0,1,1.48.1V.07c-.49,0-1-.07-1.48-.07a14.82,14.82,0,1,0,0,29.63,14.52,14.52,0,0,0,1.48-.08V25.16a10.44,10.44,0,0,1-11.71-8.08h8.58V12.55ZM16.33,25.16v4.39a14.32,14.32,0,0,1-1.47.08A14.82,14.82,0,1,1,14.86,0c.49,0,1,0,1.47.07V4.46a12,12,0,0,0-1.47-.1,10.46,10.46,0,1,0,0,20.91,10.28,10.28,0,0,0,1.47-.11"/>
        </SvgIcon>
    );
}

export function MD(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 49.66 29.63">
            <path d="M49.66,0H0V29.63H49.66V0ZM48.17,28.15H1.49V1.47H48.17Zm-16-7.71H35c3.36,0,4.85-2.46,4.85-5.83,0-2.83-1.39-5.43-4.83-5.43H32.19ZM28.4,6.16h7c5.1,0,8.44,3.32,8.44,8.44s-3.24,8.87-8.6,8.87H28.4ZM20.55,17c0-2.95.05-6.26.12-8.21h-.12c-.82,3.53-2.57,9.29-4.25,14.71H13.07C11.8,18.73,10,12.16,9.22,8.71H9.1c.15,2,.22,5.59.22,8.56v6.2H5.87V6.16h5.64c1.36,4.52,2.89,10.19,3.42,12.77h0c.43-2.27,2.35-8.33,3.81-12.77H24.2V23.47H20.55Z"/>
        </SvgIcon>
    );
}

export function Book(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 40.51 29.28">
            <path d="M27.74,9.29a2.42,2.42,0,1,1,2.42,2.41A2.42,2.42,0,0,1,27.74,9.29Zm4.66,4H25.27v1.31h2.65v6.49H25.27V22.4h9.78V21.05H32.4ZM40.51,1.76V27.3l-.23.14c-5.43,3.22-8.37,1.57-11.38-.12-2.27-1.27-4.58-2.58-8.19-1.53l-.47.15-.47-.15c-3.6-1.05-5.92.26-8.18,1.53-3,1.69-5.95,3.34-11.38.12L0,27.3V1.75l.71.42c5,3,7.66,1.43,10.44-.13C13.63.65,16.17-.78,20.26.5,24.36-.78,26.89.65,29.37,2c2.77,1.56,5.48,3.08,10.44.14ZM19.79,1.33c-3.59-1-5.92.25-8.18,1.53C8.73,4.48,5.93,6.06.94,3.38V26.77C5.76,29.53,8.42,28,11.15,26.5c2.39-1.34,4.82-2.71,8.64-1.68ZM39.58,3.38c-5,2.67-7.79,1.09-10.67-.52C26.65,1.58,24.33.29,20.73,1.33V24.82c3.82-1,6.26.35,8.64,1.69,2.73,1.53,5.39,3,10.21.26Z"/>
        </SvgIcon>
    );
}

export function Factory(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 32.15 29.63">
            <polygon points="23.49 0 23.49 16.58 23.47 16.58 19.78 10.79 16.11 16.58 15.96 16.58 12.27 10.79 8.6 16.58 8.53 16.58 4.84 10.79 0 18.41 0 29.63 32.15 29.63 32.15 0 23.49 0"/>
        </SvgIcon>
    );
}

export function LogoFooter(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 1466.5 228.3">
            <path d="m0,136.9c0,48.5 37.9,88.1 85.7,91.1l0,-56.9l0,-16.5l0,-108.8c-47.8,2.9 -85.7,42.6 -85.7,91.1zm98.9,91c32.1,-2.6 59.6,-23.7 71.6,-45.3c-22.8,14.3 -47.8,17.9 -61,7.3c-6.7,-5.7 -6.4,-5.2 -10.4,-8.9l-0.1,17.2l0,29.7l-0.1,0zm60.6,-152c-15.2,-17.4 -36,-28.1 -60.6,-30l0,94.1c22.3,-23 53.5,-56.4 60.6,-64.1z" />
            <path d="m111.8,177.5c17.7,17.1 63,-0.5 101.1,-39.3s54.7,-84.1 37,-101.2l-19.7,-18.8c17.1,17.4 0.5,62.3 -37.3,100.8c-22.3,22.7 -47.1,38.2 -67.4,43.6c-8.5,2.3 -18.4,3.5 -25.8,2.9l-0.5,0l11.4,10.8c0.4,0.4 0.8,0.8 1.2,1.2l0,0zm-12.9,-20.9c22,2 56.1,-15.4 85.8,-45.7c38.1,-38.8 54.6,-84.1 36.9,-101.2c-16.4,-15.8 -57.6,-3.8 -93.8,29.4c19.8,6.6 36.7,20.2 48.8,36.6l-77.7,80.9z" />
            <path d="m319.7,162.8l0,-105l37.2,0c37.3,0 56,17.1 56,51.2c0,16.4 -5.1,29.4 -15.3,39.2c-10.2,9.8 -23.7,14.6 -40.7,14.6l-37.2,0zm23.6,-85.8l0,66.6l11.7,0c10.3,0 18.3,-3.1 24.1,-9.2c5.8,-6.2 8.8,-14.5 8.8,-25.1c0,-10 -2.9,-17.9 -8.7,-23.6c-5.8,-5.7 -13.9,-8.6 -24.4,-8.6l-11.5,0l0,-0.1z" />
            <path d="m475.4,108.7c-2.8,-1.5 -6,-2.3 -9.7,-2.3c-5,0 -9,1.8 -11.8,5.5c-2.8,3.7 -4.2,8.7 -4.2,15.1l0,35.8l-23.1,0l0,-75l23.1,0l0,13.9l0.3,0c3.7,-10.2 10.3,-15.2 19.8,-15.2c2.4,0 4.3,0.3 5.7,0.9l0,21.3l-0.1,0z" />
            <path d="m556.4,162.8l-23.1,0l0,-11.4l-0.4,0c-5.7,8.8 -13.4,13.3 -22.9,13.3c-17.4,0 -26.1,-10.5 -26.1,-31.6l0,-45.3l23.1,0l0,43.2c0,10.6 4.2,15.9 12.6,15.9c4.1,0 7.5,-1.5 10,-4.4c2.5,-2.9 3.7,-6.8 3.7,-11.8l0,-42.9l23.1,0l0,75z" />
            <path d="m647.9,154.2c0,13.9 -4,24.7 -12.1,32.3c-8.1,7.6 -19.7,11.5 -35,11.5c-10.1,0 -18.1,-1.4 -24,-4.3l0,-19.5c7.7,4.5 15.5,6.7 23.4,6.7c7.8,0 13.9,-2.1 18.2,-6.2c4.3,-4.1 6.4,-9.7 6.4,-16.8l0,-5.9l-0.3,0c-5.3,8.4 -13.1,12.6 -23.4,12.6c-9.6,0 -17.2,-3.4 -22.8,-10.1c-5.6,-6.7 -8.4,-15.8 -8.4,-27.1c0,-12.7 3.1,-22.8 9.4,-30.2c6.2,-7.5 14.5,-11.2 24.7,-11.2c9.1,0 16,3.5 20.5,10.5l0.3,0l0,-8.7l23.1,0l0,66.4zm-22.9,-27.4l0,-5.9c0,-4.7 -1.4,-8.8 -4.2,-12.1c-2.8,-3.3 -6.5,-5 -10.9,-5c-5.1,0 -9.2,2 -12.1,6c-2.9,4 -4.4,9.6 -4.4,16.9c0,6.3 1.4,11.2 4.2,14.8s6.6,5.5 11.4,5.5c4.8,0 8.7,-1.8 11.6,-5.5c3,-3.8 4.4,-8.7 4.4,-14.7z" />
            <path d="m800.2,162.8l-25.8,0l-7.5,-23.4l-37.4,0l-7.4,23.4l-25.6,0l38.2,-105l28.1,0l37.4,105zm-38.6,-41.5l-11.3,-35.3c-0.8,-2.6 -1.4,-5.8 -1.8,-9.4l-0.6,0c-0.2,3.1 -0.9,6.1 -1.8,9.2l-11.4,35.6l26.9,0l0,-0.1z" />
            <path d="m883.1,154.2c0,13.9 -4,24.7 -12.1,32.3c-8.1,7.6 -19.7,11.5 -35,11.5c-10.1,0 -18.1,-1.4 -24,-4.3l0,-19.5c7.7,4.5 15.5,6.7 23.4,6.7c7.8,0 13.9,-2.1 18.2,-6.2c4.3,-4.1 6.4,-9.7 6.4,-16.8l0,-5.9l-0.3,0c-5.3,8.4 -13.1,12.6 -23.4,12.6c-9.6,0 -17.2,-3.4 -22.8,-10.1c-5.6,-6.7 -8.4,-15.8 -8.4,-27.1c0,-12.7 3.1,-22.8 9.4,-30.2c6.2,-7.5 14.5,-11.2 24.7,-11.2c9.1,0 16,3.5 20.5,10.5l0.3,0l0,-8.7l23.1,0l0,66.4zm-22.8,-27.4l0,-5.9c0,-4.7 -1.4,-8.8 -4.2,-12.1c-2.8,-3.3 -6.5,-5 -10.9,-5c-5.1,0 -9.2,2 -12.1,6c-2.9,4 -4.4,9.6 -4.4,16.9c0,6.3 1.4,11.2 4.2,14.8s6.6,5.5 11.4,5.5c4.8,0 8.7,-1.8 11.6,-5.5c2.9,-3.8 4.4,-8.7 4.4,-14.7z" />
            <path d="m968.3,131.9l-48.9,0c0.8,10.9 7.6,16.3 20.6,16.3c8.3,0 15.5,-2 21.8,-5.9l0,16.7c-6.9,3.7 -15.9,5.6 -27,5.6c-12.1,0 -21.5,-3.4 -28.2,-10.1c-6.7,-6.7 -10,-16.1 -10,-28.1c0,-12.5 3.6,-22.3 10.8,-29.6c7.1,-7.2 16,-10.8 26.6,-10.8c10.9,0 19.4,3.2 25.4,9.7c6,6.5 9,15.3 9,26.4l0,9.8l-0.1,0zm-21.4,-14.2c0,-10.7 -4.3,-16.1 -13,-16.1c-3.7,0 -6.9,1.5 -9.6,4.6c-2.7,3.1 -4.4,6.9 -4.9,11.5l27.5,0z" />
            <path d="m1053,162.8l-23,0l0,-41.7c0,-11.6 -4.2,-17.4 -12.5,-17.4c-4,0 -7.3,1.5 -9.9,4.6c-2.6,3.1 -3.9,7 -3.9,11.7l0,42.8l-23.1,0l0,-75l23.1,0l0,11.9l0.3,0c5.5,-9.1 13.5,-13.7 24.1,-13.7c16.6,0 24.9,10.3 24.9,30.9l0,45.9z" />
            <path d="m1126.7,160.1c-5.2,3 -12.8,4.5 -22.6,4.5c-11.5,0 -20.8,-3.5 -28,-10.5c-7.1,-7 -10.7,-16 -10.7,-27c0,-12.7 3.8,-22.8 11.5,-30.1c7.6,-7.3 17.9,-11 30.7,-11c8.8,0 15.2,1.2 19.2,3.5l0,19.6c-4.8,-3.6 -10.2,-5.4 -16.2,-5.4c-6.6,0 -11.9,1.9 -15.8,5.8c-3.9,3.9 -5.8,9.2 -5.8,16.1c0,6.6 1.9,11.9 5.6,15.6c3.7,3.8 8.8,5.7 15.3,5.7c5.8,0 11.4,-1.8 16.9,-5.4l0,18.6l-0.1,0z" />
            <path d="m1211.8,87.8l-30.5,81c-7.3,19.5 -18.4,29.2 -33.1,29.2c-5.6,0 -10.2,-0.6 -13.8,-1.9l0,-18.5c3.1,1.8 6.4,2.7 10,2.7c6,0 10.1,-2.8 12.5,-8.4l4,-9.3l-30.5,-74.9l25.6,0l14,45.6c0.9,2.8 1.6,6.2 2.1,10l0.3,0c0.4,-2.8 1.2,-6.1 2.4,-9.9l14.1,-45.8l22.9,0l0,0.2z" />
            <path d="m1321.8,162.8l-21.9,0l0,-10.8l-0.3,0c-5,8.4 -12.5,12.6 -22.3,12.6c-7.3,0 -13,-2.1 -17.2,-6.2c-4.2,-4.1 -6.3,-9.6 -6.3,-16.5c0,-14.5 8.6,-22.9 25.9,-25.2l20.4,-2.7c0,-8.2 -4.4,-12.3 -13.3,-12.3c-8.9,0 -17.4,2.7 -25.5,8l0,-17.4c3.2,-1.7 7.6,-3.1 13.2,-4.4c5.6,-1.3 10.7,-1.9 15.3,-1.9c21.4,0 32.1,10.7 32.1,32l0,44.8l-0.1,0zm-21.7,-30.5l0,-5.1l-13.6,1.8c-7.5,1 -11.3,4.4 -11.3,10.2c0,2.6 0.9,4.8 2.7,6.5c1.8,1.7 4.3,2.5 7.4,2.5c4.3,0 7.9,-1.5 10.6,-4.5c2.8,-3 4.2,-6.8 4.2,-11.4z" />
            <path d="m1349,164.6c-3.8,0 -7,-1.2 -9.5,-3.5c-2.5,-2.3 -3.8,-5.2 -3.8,-8.6c0,-3.5 1.3,-6.4 3.9,-8.6c2.6,-2.2 5.9,-3.4 9.8,-3.4c4,0 7.2,1.1 9.7,3.4c2.5,2.3 3.8,5.1 3.8,8.6c0,3.6 -1.3,6.5 -3.8,8.8c-2.7,2.2 -6,3.3 -10.1,3.3z" />
            <path d="m1372.9,160.8l0,-18.8c3.8,2.3 7.6,4 11.4,5.1c3.8,1.1 7.4,1.7 10.7,1.7c4.1,0 7.3,-0.6 9.7,-1.7c2.4,-1.1 3.6,-2.8 3.6,-5.1c0,-1.5 -0.5,-2.7 -1.6,-3.7c-1.1,-1 -2.5,-1.8 -4.1,-2.6c-1.7,-0.7 -3.5,-1.4 -5.5,-2c-2,-0.6 -3.9,-1.2 -5.8,-2c-3,-1.1 -5.6,-2.3 -7.9,-3.6c-2.3,-1.3 -4.2,-2.8 -5.7,-4.5c-1.5,-1.7 -2.7,-3.7 -3.5,-5.9c-0.8,-2.2 -1.2,-4.9 -1.2,-8c0,-4.2 0.9,-7.8 2.7,-10.8c1.8,-3 4.3,-5.5 7.3,-7.4c3.1,-1.9 6.5,-3.3 10.5,-4.2c3.9,-0.9 8,-1.4 12.3,-1.4c3.3,0 6.7,0.3 10.1,0.8c3.4,0.5 6.8,1.3 10.1,2.2l0,17.9c-2.9,-1.7 -6.1,-3 -9.4,-3.8c-3.3,-0.9 -6.6,-1.3 -9.9,-1.3c-1.5,0 -2.9,0.1 -4.3,0.4c-1.3,0.3 -2.5,0.7 -3.6,1.2s-1.8,1.2 -2.4,2s-0.9,1.7 -0.9,2.7c0,1.4 0.4,2.5 1.3,3.5c0.9,1 2,1.8 3.4,2.5c1.4,0.7 3,1.3 4.7,1.9c1.7,0.6 3.5,1.1 5.2,1.7c3.1,1.1 5.9,2.2 8.3,3.5c2.5,1.3 4.6,2.8 6.4,4.5c1.8,1.7 3.1,3.7 4.1,6c1,2.3 1.4,5 1.4,8.2c0,4.4 -1,8.2 -2.9,11.4c-1.9,3.1 -4.5,5.7 -7.7,7.7c-3.2,2 -6.9,3.4 -11.1,4.3c-4.2,0.9 -8.5,1.4 -13,1.4c-8,0 -15.7,-1.3 -22.7,-3.8z" />
            <path d="m1452.6,164.6c-3.8,0 -7,-1.2 -9.5,-3.5c-2.5,-2.3 -3.8,-5.2 -3.8,-8.6c0,-3.5 1.3,-6.4 3.9,-8.6c2.6,-2.2 5.9,-3.4 9.8,-3.4c4,0 7.2,1.1 9.7,3.4c2.5,2.3 3.8,5.1 3.8,8.6c0,3.6 -1.3,6.5 -3.8,8.8c-2.6,2.2 -6,3.3 -10.1,3.3z" />
        </SvgIcon>
    );
}
