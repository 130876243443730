import { configureStore } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist';

import reducer from './reducer';
import thunk from 'redux-thunk'

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = configureStore({
    reducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
  });
const persister = persistStore(store);

export { store, persister };
