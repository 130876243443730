import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import axios from 'utils/Axios';
import { getLoginWithReturnUrl } from 'utils/Login';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const { isLoggedIn, handleNewToken } = useAuth();
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const ott = searchParams.get('ott');
        if (!isLoggedIn) {
            if (ott) {
                axios.post(`/auth/ott`, { token: ott }).then(resp => {
                    handleNewToken(resp.data.token);
                }).catch(err => {

                    navigate(getLoginWithReturnUrl(), { replace: true });
                });
            }
            else {
                navigate(getLoginWithReturnUrl(), { replace: true });
            }
        }
        else {
            if (ott) {
                searchParams.delete('ott');
                setSearchParams(searchParams);
                window.location.reload();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
