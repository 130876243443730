import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/authReducer';
import authReducer from 'store/authReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/Axios';

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (token) => {
    if (!token) {
        return false;
    }
    const decoded = jwtDecode(token);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (user, validate) => {
    if (user && user.token && verifyToken(user.token)) {
        localStorage.setItem('user', JSON.stringify(user));
        axios.defaults.headers.common.Authorization = `Bearer ${user.token}`;
    } else {
        localStorage.removeItem('user');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);
    const [refreshTimer, setRefreshTimer] = useState(null);

    useEffect(() => {
        const init = async () => {
            const user = JSON.parse(window.localStorage.getItem('user'));
            if (user && user.token && verifyToken(user.token)) {

                setSession(user);

                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user
                    }
                });

                startRefreshTimer(user.token);
            } else {
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
        // eslint-disable-next-line
    }, []);

    const startRefreshTimer = (token) => {
        const decoded = jwtDecode(token);
        const expirationTime = decoded.exp * 1000; // Převod exp času na milisekundy

        // Čas do vypršení tokenu mínus určitý časový polštář (12 hodin)
        const refreshInterval = expirationTime - Date.now() - 43200000;

        // Nastavení intervalu pro obnovu tokenu
        if (refreshInterval < 0) {
            refreshToken();
        }
        else {
            const timer = setInterval(() => {
                refreshToken();
                stopRefreshTimer();
            }, Math.max(refreshInterval, 0));
            setRefreshTimer(timer);
        }
    };

    const stopRefreshTimer = () => {
        // Zastavení intervalu pro obnovu tokenu
        clearInterval(refreshTimer);
        setRefreshTimer(null);
    };

    const login = async (email, password) => {
        const response = await axios.post('/auth', { email, password });
        const { token } = response.data;
        handleNewToken(token);
    };

    const ipLogin = async (email ) => {
        const response = await axios.post('/auth/ip', { email });
        const { token } = response.data;
        handleNewToken(token);
    };

    const handleNewToken = (token) => {
        const decoded = jwtDecode(token);

        const user =
        {
            email: decoded.sub,
            role: decoded.role,
            dbu: decoded.dbu,
            firstName: decoded.fname,
            lastName: decoded.lname,
            cin: decoded.cin,
            tin: decoded.tin,
            token: token
        };

        setSession(user);
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });
        startRefreshTimer(user.token);
    };

    const logout = async () => {
        try { await axios.delete(`/auth`); }
        catch(err) {}
        stopRefreshTimer();
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const register = async (email, password, firstName, lastName, licenseAgreement) => {
        axios.post('/users/register', {
            email,
            firstName,
            lastName,
            password,
            licenseAgreement
        });
    };

    const confirmEmail =  async(email, code, password) => {
        const response = await axios.post('/users/confirm', {
            email,
            token: code,
            password
        });

        const { token } = response.data;
        handleNewToken(token);
    };

    const forgotPassword =  async(email) => {
        axios.post('/users/forgot-password', {
            email
        });

    };

    const restorePassword =  async(email, code, password) => {
        const response = await axios.post('/users/restore-password', {
            email,
            token: code,
            password
        });

        const { token } = response.data;
        handleNewToken(token);
    };

    const changePassword = async (password, newPassword, confirmNewPassword) => {
        var response = await axios.post('/users/change-password',
        {
            old: password,
            new: newPassword,
            confirm: confirmNewPassword
        });

        const { token } = response.data;
        handleNewToken(token);
    }

    const updateProfile = async (firstName, lastName, companyIdentificationNumber, taxIdentificationNumber) => {
        var response = await axios.post('/users/update',
        {
            firstName,
            lastName,
            companyIdentificationNumber,
            taxIdentificationNumber
        });

        const { token } = response.data;
        handleNewToken(token);
    };

    const refreshToken = async () => {
        var response = await axios.post('/auth/refresh-token');

        const { token } = response.data;
        handleNewToken(token);
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, ipLogin, logout, register, confirmEmail, forgotPassword, restorePassword, updateProfile, changePassword, handleNewToken, refreshToken }}>{children}</JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default JWTContext;
