import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

// material-ui
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Container, IconButton, Tooltip } from '@mui/material';
import Alert from '@mui/material/Alert';
import PersonIcon from '@mui/icons-material/Person';

// project imports
import HeaderOutlet from 'layout/MainLayout/Header';
import FooterOutlet from 'layout/MainLayout/Footer';
import useAuth from 'hooks/useAuth';
import { hasDbAccess } from 'utils/User';

// assets
import { PATH_ACCOUNT } from 'routes/paths'

const Body = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    // minWidth: '25rem',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary.light
}));

const Header = styled('div')(({ theme }) => ({
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
    backgroundColor: theme.palette.background.default,
    borderBottom: '1px solid',
    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.grey[200],
    color: theme.palette.text.dark
}));

const Footer = styled('div')(({ theme }) => ({
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto'
}));

const MainContainer = styled(Container)(({ theme }) => ({
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 'auto',
    minHeight: 0,
    padding: '0 20px 0 20px',
    display: 'flex',
    flexDirection: 'column'
}));

const MainLayout = () => {
    const theme = useTheme();
    const { user, isLoggedIn } = useAuth();
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Body>
            {  !isLoggedIn || hasDbAccess(user)
                ? <Fragment />
                :
                    <Alert severity="warning" sx={{borderRadius: 0}}>
                        {user.dbu
                            ? `Platnost Vaší licence vypršela ${new Date(user.dbu * 1000).toLocaleDateString('cs-CZ')}`
                            : 'Pro používání aplikace je nutná platná licence' }

                            <Tooltip title={user.dbu ? 'Prodlužte si licenci o 1 rok v nastavení účtu' : 'Zakupte si licenci o 1 rok v nastavení účtu'}>
                                <span>
                                    <IconButton
                                        aria-label={
                                            user.dbu
                                                ? 'Prodlužte si licenci o 1 rok v nastavení účtu'
                                                : 'Zakupte si licenci o 1 rok v nastavení účtu'
                                        }
                                        sx={{color: 'inherit'}}
                                        component={RouterLink}
                                        to={PATH_ACCOUNT.root}
                                    >
                                        <PersonIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                    </Alert>

            }
            <Header>
                <HeaderOutlet />
            </Header>

            <MainContainer disableGutters={mdDown}>
                <Outlet />
            </MainContainer>

            <Footer>
                <Container disableGutters={mdDown} sx={{padding: '0 20px 0 20px'}}>
                    <FooterOutlet />
                </Container>
            </Footer>
        </Body>
    );
};

export default MainLayout;
