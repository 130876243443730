
export const pushSorted = (el, arr, compare = (x, y) => x > y) => {
    if(arr.length === 0) arr.push(el)
    else {
      let isLast = true;
      for (let i = 0, len = arr.length; i < len; i++) {
        if (compare(arr[i], el)) {
          isLast = false;
          arr.splice(i, 0, el);
          break;
        }
      }

      if(isLast) arr.push(el);
    }
  }
