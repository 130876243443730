import { createTransform } from 'redux-persist';
import { createDrugsLookup } from 'utils/Drugs';

export default function createTransformCompress(config) {
    return createTransform(
        (state, key) => {
            if (key === 'drugsLookup') return null;
            return state;
        },
        (state, key, fullState) => {
            if (key === 'drugsLookup')
                return createDrugsLookup(JSON.parse(fullState.drugs));
            return state;
        },
        config
    )
  }
