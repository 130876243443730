import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'utils/Axios';
import { SNACKBAR_OPEN } from 'store/actions';

export const getDrugs = createAsyncThunk('interactions/getDrugs', async (arg, thunkAPI) => {
    const state = thunkAPI.getState();

    try
    {
        const apiVersion = await (await axios.get(`/info`)).data.version;

        const forceUpdate = apiVersion > localStorage.getItem('data-version');

        if(forceUpdate || state.interactions.drugs.length === 0)
        {
            const response = await axios.get(`/drugs?rql=$m=sukl,suklCodes,name,form.name,atc,atc.oldAgeOverallWeight$o=name`);
            localStorage.setItem('data-version', apiVersion);

            response.data.forEach(drug => {
                if (drug.atc === 'N05BA12') {
                    console.log(drug['atc.oldAgeOverallWeight']);
                    console.log(drug);
                }
                // if (drug['atc.oldAgeOverallWeight'] === undefined) {
                //     if (drug.atc === 'N05BA12')
                //     delete drug.atc;
                // }
            });

            return response.data;
        }
    } catch (error) {
        if (error.status !== 401) {
            thunkAPI.dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'Načítání léků selhalo je možné že aplikace nebude správně fungovat.',
                variant: 'alert',
                alertSeverity: 'error',
            });
        }
    };


    return thunkAPI.fulfillWithValue(state.interactions.drugs);
});

const initialState = {
    drugs: [],
    selectedDrugs: []
}

const interactionsSlice = createSlice({
    name: 'interactions',
    initialState,
    reducers: {
        setSelectedDrugs: (state, action) => {
            if (state.selectedDrugs !== action.payload) {
                state.selectedDrugs = action.payload;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getDrugs.fulfilled, (state, action) => {
          state.drugs = action.payload;
        });
    },
});

export const {
    setSelectedDrugs
} = interactionsSlice.actions;

export default interactionsSlice.reducer;
