import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { PATH_HOME, PATH_ABOUT_INTERACTIONS, PATH_ABOUT_DATABASE } from 'routes/paths'

// Home
const HomePage = Loadable(lazy(() => import('views/home')));
// O interakcích
const AboutInteractionsPage = Loadable(lazy(() => import('views/about-interactions')));
// O databázi
const DatabaseCreationPage = Loadable(lazy(() => import('views/about-database/DatabaseCreation')));
const EvaluationOfInteractionsPage = Loadable(lazy(() => import('views/about-database/EvaluationOfInteractions')));
const WorkWithDatabsePage = Loadable(lazy(() => import('views/about-database/WorkWithDatabse')));
const GlossaryPage = Loadable(lazy(() => import('views/about-database/Glossary')));
const LicenseAgreementPage = Loadable(lazy(() => import('views/about-database/LicenseAgreement')));
const TermsAndConditionsPage = Loadable(lazy(() => import('views/about-database/TermsAndConditions')));


// ==============================|| MAIN ROUTING ||============================== //

const GuestRoutes = {
    path: '/',
    element: (
        <MainLayout />
    ),
    children: [
        {
            path: PATH_HOME.root,
            element: <HomePage />
        },
        {
            path: PATH_ABOUT_INTERACTIONS.root,
            element: <AboutInteractionsPage />
        },
        {
            path: PATH_ABOUT_DATABASE.databaseCreation,
            element: <DatabaseCreationPage />
        },
        {
            path: PATH_ABOUT_DATABASE.evaluationOfInteractions,
            element: <EvaluationOfInteractionsPage />
        },
        {
            path: PATH_ABOUT_DATABASE.workWithDatabse,
            element: <WorkWithDatabsePage />
        },
        {
            path: PATH_ABOUT_DATABASE.glossary,
            element: <GlossaryPage />
        },
        {
            path: PATH_ABOUT_DATABASE.licenseAgreement,
            element: <LicenseAgreementPage />
        },
        {
            path: PATH_ABOUT_DATABASE.termsAndConditions,
            element: <TermsAndConditionsPage />
        },
    ]
};

export default GuestRoutes;
