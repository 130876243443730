import { Outlet } from 'react-router-dom';

// ==============================|| NO LAYOUT ||============================== //

const NoLayout = () => (
    <>
        <Outlet />
    </>
);

export default NoLayout;
