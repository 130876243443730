import { createAction } from '@reduxjs/toolkit'

// project imports
import config from 'config';

// action - customization reducer
export const setTheme = createAction('customization/setTheme')

export const initialState = {
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    outlinedFilled: config.outlinedFilled,
    navType: config.theme,
    presetColor: config.presetColor
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case setTheme.type:
            return {
                ...state,
                navType: action.payload
            };
        default:
            return { ...state };
    }
};

export default customizationReducer;
