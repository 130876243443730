import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import Snackbar from 'ui-component/extended/Snackbar';

// auth provider
import { JWTProvider } from 'contexts/JWTContext';

function App() {
  const customization = useSelector((state) => state.customization);

  useEffect(() => {
    localStorage.removeItem("app-interaction");
}, []);

  return (
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
            <CssBaseline />
            <NavigationScroll>
                <JWTProvider>
                  <Routes />
                  <Snackbar />
                </JWTProvider>
            </NavigationScroll>
        </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
