import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import NoLayout from 'layout/NoLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import { PATH_AUTH } from 'routes/paths'

// login routing
const AuthLogin = Loadable(lazy(() => import('views/auth/Login')));
const NotFoundPage = Loadable(lazy(() => import('views/errors/NotFound')));
const AuthRegister = Loadable(lazy(() => import('views/auth/Register')));
const AuthConfirmEmail = Loadable(lazy(() => import('views/auth/ConfirmEmail')));
const AuthForgotPassword = Loadable(lazy(() => import('views/auth/ForgotPassword')));
const AuthRestorePassword = Loadable(lazy(() => import('views/auth/RestorePassword')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <NoLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: PATH_AUTH.login,
            element: <AuthLogin />
        },
        {
            path: PATH_AUTH.register,
            element: <AuthRegister />
        },
        {
            path: PATH_AUTH.confirmEmail,
            element: <AuthConfirmEmail />
        },
        {
            path: PATH_AUTH.forgotPassword,
            element: <AuthForgotPassword />
        },
        {
            path: PATH_AUTH.restorePassword,
            element: <AuthRestorePassword />
        },
        {
            path: '*',
            element: <NotFoundPage />
        }
    ]
};

export default LoginRoutes;
