import { PATH_AUTH } from 'routes/paths'

export const getLoginWithReturnUrl = ()  => {
    const url = new URL(window.location);
    const redirectTo = PATH_AUTH.login +
        ((url.pathname === '/')
            ? ''
            : '?url=' + encodeURIComponent(url.pathname + url.search));
    return redirectTo;
}
