import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Box,
    Button,
    Container,
    Drawer,
    IconButton,
    Link,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Toolbar,
    Typography
} from '@mui/material';
import MuiTooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

// project imports
import Logo from 'ui-component/Logo';
import { setTheme } from 'store/customizationReducer';
import useAuth from 'hooks/useAuth';

// assets
import MenuIcon from '@mui/icons-material/Menu';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';

import { PATH_HOME, PATH_AUTH, PATH_INTERACTIONS, PATH_ABOUT_INTERACTIONS, PATH_ABOUT_DATABASE, PATH_ACCOUNT } from 'routes/paths'

const NestedListItemButton = styled(ListItemButton)(({ theme }) => ({
    paddingLeft: '32px'
}));

const Tooltip = styled(({ ...props }) => (
    <MuiTooltip {...props} arrow placement="bottom" TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }} />
))``;

// ==============================|| MINIMAL LAYOUT APP BAR ||============================== //

const Header = ({ ...others }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { logout, isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const [anchorAboutDb, setAnchorAboutDb] = useState(null);
    const openAboutDb = Boolean(anchorAboutDb);

    const [anchorAccount, setAnchorAccount] = useState(null);
    const openAccount = Boolean(anchorAccount);

    const [drawerToggle, setDrawerToggle] = useState(false);

    const [expandAboutDatabase, setExpandAboutDatabase] = useState(false);

    /** Method called on multiple components with different event types */
    const drawerToggler = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerToggle(open);
    };

    const toggleThemeMode = (event) => {
        dispatch(setTheme(theme.palette.mode === 'dark' ? 'light' : 'dark'));
    };

    const handleLogin = async () => {
        navigate(PATH_AUTH.login, { replace: true });
    };

    const handleLogout = async () => {
        handleAccountClose();
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    const handleAboutDbOpen = (event) => {
        if(!anchorAboutDb) setAnchorAboutDb(event.currentTarget);
    };

    const handleAboutDbClose = () => {
        setAnchorAboutDb(null);
    };

    const toggleExpandAboutDatabase =  () => {
        setExpandAboutDatabase(!expandAboutDatabase);
    };

    const handleAccountOpen = (event) => {
        if(!anchorAccount) setAnchorAccount(event.currentTarget);
    };

    const handleAccountClose = () => {
        setAnchorAccount(null);
    };

    return (
        <Container disableGutters={mdDown}>
            <Toolbar>
                <Typography component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
                    <Tooltip title='Databáze lékových interakcí'>
                        <span>
                            <Link component={RouterLink} to={PATH_HOME.root}>
                                <Logo />
                            </Link>
                        </span>
                    </Tooltip>
                </Typography>
                <Stack direction="row" sx={{ display: { xs: 'none', md: 'block' } }} spacing={2}>
                    <Button sx={{textTransform: 'none', color: theme.palette.text.primary}} color="inherit" component={RouterLink} to={PATH_INTERACTIONS.root}>
                        Kontrola preskripce
                    </Button>
                    <Button sx={{textTransform: 'none', color: theme.palette.text.primary}} color="inherit" component={RouterLink} to={PATH_ABOUT_INTERACTIONS.root}>
                        O interakcích
                    </Button>
                    <Button
                        sx={{textTransform: 'none', color: theme.palette.text.primary}}
                        color="inherit"
                        aria-controls={openAboutDb ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openAboutDb ? 'true' : undefined}
                        // onMouseEnter={handleAboutDbOpen}
                        onClick={handleAboutDbOpen}
                    >
                        O Databázi
                    </Button>
                    <Menu
                        id="about-database-menu"
                        anchorEl={anchorAboutDb}
                        open={openAboutDb}
                        onClose={handleAboutDbClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem component={RouterLink} to={PATH_ABOUT_DATABASE.databaseCreation} onClick={handleAboutDbClose}>Tvorba databáze</MenuItem>
                        <MenuItem component={RouterLink} to={PATH_ABOUT_DATABASE.evaluationOfInteractions} onClick={handleAboutDbClose}>Hodnocení interakcí</MenuItem>
                        <MenuItem component={RouterLink} to={PATH_ABOUT_DATABASE.workWithDatabse} onClick={handleAboutDbClose}>Práce s databází</MenuItem>
                        <MenuItem component={RouterLink} to={PATH_ABOUT_DATABASE.glossary} onClick={handleAboutDbClose}>Slovník pojmů</MenuItem>
                        <MenuItem component={RouterLink} to={PATH_ABOUT_DATABASE.licenseAgreement} onClick={handleAboutDbClose}>Licenční ujednání</MenuItem>
                        <MenuItem component={RouterLink} to={PATH_ABOUT_DATABASE.termsAndConditions} onClick={handleAboutDbClose}>Obchodní podmínky</MenuItem>
                    </Menu>
                    <Tooltip title={theme.palette.mode === 'dark' ? 'Světlý režim' : 'Tmavý režim'}>
                        <span>
                            <IconButton sx={{color: theme.palette.text.primary}} aria-label={theme.palette.mode === 'dark' ? 'Světlý režim' : 'Tmavý režim'} component="span" onClick={toggleThemeMode}>
                            {
                                theme.palette.mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />
                            }
                            </IconButton>
                        </span>
                    </Tooltip>
                    {isLoggedIn
                    ?
                        <>
                            <Tooltip title='Účet'>
                                <span>
                                    <IconButton sx={{color: theme.palette.text.primary}} aria-label='Účet' component="span" onClick={handleAccountOpen}>
                                    {
                                        <PersonIcon />
                                    }
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Menu
                                id="account-menu"
                                anchorEl={anchorAccount}
                                open={openAccount}
                                onClose={handleAccountClose}
                                MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem component={RouterLink} to={PATH_ACCOUNT.root} onClick={handleAccountClose}>
                                    <ListItemIcon sx={{color: theme.palette.text.primary}}>
                                        <ManageAccountsIcon />
                                    </ListItemIcon>
                                    Účet
                                </MenuItem>
                                <MenuItem component='span' onClick={handleLogout}>
                                    <ListItemIcon sx={{color: theme.palette.text.primary}}>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    Odhlásit
                                </MenuItem>
                            </Menu>
                        </>
                    :
                        <Tooltip title='Přihlásit'>
                            <span>
                                <IconButton sx={{color: theme.palette.text.primary}} aria-label='Přihlásit' component={RouterLink} to={PATH_AUTH.login}>
                                {
                                    <LoginIcon />
                                }
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                </Stack>
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <IconButton color="inherit" onClick={toggleThemeMode} size="large">
                        {theme.palette.mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
                    </IconButton>
                    <IconButton color="inherit" onClick={drawerToggler(true)} size="large">
                        <MenuIcon />
                    </IconButton>
                    <Drawer anchor="top" open={drawerToggle} onClose={drawerToggler(false)}>
                        <Box
                            sx={{
                                width: 'auto'
                            }}
                            role="presentation"
                        >
                            <List>
                                <Link style={{ textDecoration: 'none' }} href="#">
                                    <ListItemButton onKeyDown={drawerToggler(false)} onClick={drawerToggler(false)} component={RouterLink} to={PATH_INTERACTIONS.root}>
                                        <ListItemText primary="Kontrola preskripce" />
                                    </ListItemButton>
                                </Link>
                                <Link style={{ textDecoration: 'none' }} href="#">
                                    <ListItemButton onKeyDown={drawerToggler(false)} onClick={drawerToggler(false)} component={RouterLink} to={PATH_ABOUT_INTERACTIONS.root}>
                                        <ListItemText primary="O interakcích" />
                                    </ListItemButton>
                                </Link>
                                <ListItemButton onClick={toggleExpandAboutDatabase} >
                                    <ListItemText primary="O databázi" />
                                    { expandAboutDatabase ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                                </ListItemButton>
                                { expandAboutDatabase ?
                                    <List disablePadding>
                                        <Link style={{ textDecoration: 'none' }} href="#">
                                            <NestedListItemButton onKeyDown={drawerToggler(false)} onClick={drawerToggler(false)} component={RouterLink} to={PATH_ABOUT_DATABASE.databaseCreation}>
                                                <ListItemText primary="Tvorba databáze" />
                                            </NestedListItemButton>
                                        </Link>
                                        <Link style={{ textDecoration: 'none' }} href="#">
                                            <NestedListItemButton onKeyDown={drawerToggler(false)} onClick={drawerToggler(false)} component={RouterLink} to={PATH_ABOUT_DATABASE.evaluationOfInteractions}>
                                                <ListItemText primary="Hodnocení interakcí" />
                                            </NestedListItemButton>
                                        </Link>
                                        <Link style={{ textDecoration: 'none' }} href="#">
                                            <NestedListItemButton onKeyDown={drawerToggler(false)} onClick={drawerToggler(false)} component={RouterLink} to={PATH_ABOUT_DATABASE.workWithDatabse}>
                                                <ListItemText primary="Práce s databází" />
                                            </NestedListItemButton>
                                        </Link>
                                        <Link style={{ textDecoration: 'none' }} href="#">
                                            <NestedListItemButton onKeyDown={drawerToggler(false)} onClick={drawerToggler(false)} component={RouterLink} to={PATH_ABOUT_DATABASE.glossary}>
                                                <ListItemText primary="Slovník pojmů" />
                                            </NestedListItemButton>
                                        </Link>
                                        <Link style={{ textDecoration: 'none' }} href="#">
                                            <NestedListItemButton onKeyDown={drawerToggler(false)} onClick={drawerToggler(false)} component={RouterLink} to={PATH_ABOUT_DATABASE.licenseAgreement}>
                                                <ListItemText primary="Licenční ujednání" />
                                            </NestedListItemButton>
                                        </Link>
                                        <Link style={{ textDecoration: 'none' }} href="#">
                                            <NestedListItemButton onKeyDown={drawerToggler(false)} onClick={drawerToggler(false)} component={RouterLink} to={PATH_ABOUT_DATABASE.termsAndConditions}>
                                                <ListItemText primary="Obchodní podmínky" />
                                            </NestedListItemButton>
                                        </Link>
                                    </List>
                                : <></> }
                                { isLoggedIn
                                    ?
                                        <Link style={{ textDecoration: 'none' }} href="#">
                                            <ListItemButton onKeyDown={drawerToggler(false)} onClick={drawerToggler(false)} component={RouterLink} to={PATH_ACCOUNT.root}>
                                                <ListItemText primary="Účet" />
                                            </ListItemButton>
                                        </Link>
                                    : <></>
                                }
                                <Link style={{ textDecoration: 'none' }} href="#">
                                    <ListItemButton
                                        onKeyDown={drawerToggler(false)}
                                        onClick={(event) => {
                                            drawerToggler(false)(event);
                                            isLoggedIn ? handleLogout(event) : handleLogin(event);
                                        }}
                                        component="span"
                                    >
                                        <ListItemText primary={isLoggedIn ? 'Odhlásit' : 'Přihlásit'} />
                                    </ListItemButton>
                                </Link>

                            </List>
                        </Box>
                    </Drawer>
                </Box>
            </Toolbar>
        </Container>
    );
};

export default Header;
