import {pushSorted} from 'utils/Array'

export const padSukl = (a) => {
    if(!a) return null;
    return(1e15 + a + '').slice(-7);
}

const getOrCreateDrugLeft = (code, name, arr) => {
  let drugLeft = arr.find(i => i.sukl === code);
  if(!drugLeft) {
    drugLeft = {sukl: code, name, highestAssessment: 0, drugs: []};
    arr.push(drugLeft);
  }
  return drugLeft;
};

const getOrCreateDrugRight = (drugLeft, code, name) => {
  let drugRight = drugLeft.drugs.find(i => i.code === code);
if(!drugRight) {
  drugRight = {code, highestAssessment: 0, interactions: []};
  if(name) drugRight['name'] = name;
  pushSorted(drugRight, drugLeft.drugs, (x, y) =>
      x.interactions.length > 0 &&
    y.interactions.length > 0 &&
    x.interactions[0].assessment > y.interactions[0].assessment);
}
return drugRight;
};

const pushInteraction = (drugRight, interaction, mirror = false) => {
  const atc1 = { code: interaction.atc1, name: interaction['atc1.name'] };
  const atc2 = { code: interaction.atc2, name: interaction['atc2.name'] };

  if(drugRight.highestAssessment < interaction.assessment)
    drugRight.highestAssessment = interaction.assessment;

  const found = drugRight.interactions.find(i => (i.assessment === interaction.assessment)
    || (i.assessment > 6 && interaction.assessment > 6));

  const pair = mirror ? {atc1: atc2, atc2: atc1} : {atc1, atc2};
  if(found) {
      found.atcPairs.push(pair);
  }
  else {
      const newInteraction = { assessment: interaction.assessment, atcPairs: [pair] };
      pushSorted(newInteraction, drugRight.interactions, (x, y) => x.assessment < y.assessment);
  }
};

export const normalizedInteractions = (interactions) => {
    interactions.forEach(interaction => {
        interaction.sukl1 = padSukl(interaction.sukl1);
        interaction.sukl2 = padSukl(interaction.sukl2);
    });

    return interactions;
}

export const normalizeDrugs = (interactions) => {
  let res = [];
  interactions.forEach(interaction => {
    const drugLeft1 = getOrCreateDrugLeft(interaction.sukl1, interaction['sukl1.name'], res);
    const drugLeft2 = getOrCreateDrugLeft(interaction.sukl2, interaction['sukl2.name'], res);

    const drugRight1 = getOrCreateDrugRight(drugLeft1, interaction.sukl2, interaction['sukl2.name']);
    const drugRight2 = getOrCreateDrugRight(drugLeft2, interaction.sukl1, interaction['sukl1.name']);

    pushInteraction(drugRight1, interaction);
    pushInteraction(drugRight2, interaction, true);

    if (drugLeft1.highestAssessment < drugRight1.highestAssessment)
        drugLeft1.highestAssessment = drugRight1.highestAssessment;

    if (drugLeft2.highestAssessment < drugRight2.highestAssessment)
        drugLeft2.highestAssessment = drugRight2.highestAssessment;
  });
  return res;
}

export const normalizeFood = (interactions) => {
  let res = [];
  interactions.forEach(interaction => {
    const drugFirst = interaction.sukl1 ? true : false;
    const drugLeft = getOrCreateDrugLeft(
        drugFirst ? interaction.sukl1 : interaction.sukl2,
        drugFirst ? interaction['sukl1.name'] : interaction['sukl2.name'],
        res);
    const drugRight = getOrCreateDrugRight(
        drugLeft,
        drugFirst ? interaction.atc2 : interaction.atc1,
        drugFirst ? interaction['atc2.name'] : interaction['atc1.name']);
    pushInteraction(drugRight, interaction, !drugFirst);

    if(drugLeft.highestAssessment < drugRight.highestAssessment)
        drugLeft.highestAssessment = drugRight.highestAssessment;
  });

  return res;
}
