import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

import { PATH_INTERACTIONS, PATH_ACCOUNT } from 'routes/paths'

// Kontrola preskripce
const InteractionsPage = Loadable(lazy(() => import('views/interactions')));
const PLIPage = Loadable(lazy(() => import('views/interactions/PLI')));
const AccountPage = Loadable(lazy(() => import('views/account')));
const ExtendLicensePage = Loadable(lazy(() => import('views/account/ExtendLicense')));
const PaymentStatusPage = Loadable(lazy(() => import('views/account/PaymentStatus')));

// ==============================|| MAIN ROUTING ||============================== //

const UserRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: PATH_INTERACTIONS.root,
            element: <InteractionsPage />
        },
        {
            path: `${PATH_INTERACTIONS.root}/:sukl`,
            element: <PLIPage />
        },
        {
            path: PATH_ACCOUNT.root,
            element: <AccountPage />
        },
        {
            path: PATH_ACCOUNT.extendLicense,
            element: <ExtendLicensePage />
        },
        {
            path: PATH_ACCOUNT.paymentStatus,
            element: <PaymentStatusPage />
        },
    ]
};

export default UserRoutes;
