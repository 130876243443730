import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom'

// project imports
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    let searchParams = useSearchParams()[0];

    useEffect(() => {
        if (isLoggedIn) {
            const url = searchParams.get('url');
            if (url) navigate(url, { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
