import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from './authReducer';
import interactionsReducer from './interactionsSlice';
import createTransformDrugs from '../utils/ReduxPersistTransformDrugs'
import snackbarReducer from './snackbarReducer';

const reducer = combineReducers({
    auth: authReducer,
    customization: persistReducer(
        {
            key: 'customization',
            storage,
            keyPrefix: 'app-'
        },
        customizationReducer
    ),
    snackbar: snackbarReducer,
    interactions: persistReducer(
        {
            transforms: [createTransformDrugs()],
            key: 'drugs',
            storage,
            keyPrefix: 'app-',
            whitelist: ['drugs']
        },
        interactionsReducer
    )
});

export default reducer;
