/**
 * axios setup to use mock service
 */

import axios from 'axios';
import { store } from 'store';
import { SNACKBAR_OPEN } from 'store/actions';

const axiosServices = axios.create();
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    axiosServices.defaults.baseURL = 'https://api.lekoveinterakce.cz';
}
else {
    axiosServices.defaults.baseURL = 'https://api.lekoveinterakce.cz';
}

// Přidáno pouze pro univerzitu karlovu a jejich EZProxy
axiosServices.defaults.withCredentials = true;

//validate response
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            try {
                localStorage.removeItem('user');
                delete axios.defaults.headers.common.Authorization;
                setTimeout(() => {
                    window.location.reload();
                  }, 4000);
                store.dispatch(({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'Vaše relace vypršela, budete automaticky odhlášeni.',
                    variant: 'alert',
                    alertSeverity: 'error',
                }));
            }
            catch(err) {
                return Promise.reject(error);
            }
        }

        return Promise.reject((error.response && {message: error.response.data.message, status: error.response.status}) || {message: 'Volání vzdáleného serveru selhalo', status: error.response.status});
    }
);

export default axiosServices;
