import { padSukl } from './Interactions'

export const normalizeSuklCodes = (suklCodes) => {
    const arr = suklCodes.split(',');

    for (let i = 0; i < arr.length; i++) 	{
        arr[i] = padSukl(arr[i]);
    }

    return arr.join(',');
};

export const createDrugsLookup = (drugs) => {
    /// Build sukl lookup table.
    const drugsLookup = {};
    drugs.forEach((drug, i) => {
        drug.sukl = padSukl(drug.sukl);
        drug.suklCodes = normalizeSuklCodes(drug.suklCodes);
        const suklCodes = drug.suklCodes.split(',');
        suklCodes.forEach(suklCode => {
            drugsLookup[suklCode] = i;
        });
    });

    return drugsLookup;
};
