import { useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoFooter = () => {
    const theme = useTheme();
    const [hover, setHover] = useState(false);
    const color = hover
        ? theme.palette.mode === 'dark' ? '#9ea8ce' : '#818181'
        : theme.palette.text.primary;

    return (
        <svg height="30" viewBox="0 0 1466.5 228.3" xmlns="http://www.w3.org/2000/svg" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <path fill={color} d="m0,136.9c0,48.5 37.9,88.1 85.7,91.1l0,-56.9l0,-16.5l0,-108.8c-47.8,2.9 -85.7,42.6 -85.7,91.1zm98.9,91c32.1,-2.6 59.6,-23.7 71.6,-45.3c-22.8,14.3 -47.8,17.9 -61,7.3c-6.7,-5.7 -6.4,-5.2 -10.4,-8.9l-0.1,17.2l0,29.7l-0.1,0zm60.6,-152c-15.2,-17.4 -36,-28.1 -60.6,-30l0,94.1c22.3,-23 53.5,-56.4 60.6,-64.1z" />
            <path fill={color} d="m111.8,177.5c17.7,17.1 63,-0.5 101.1,-39.3s54.7,-84.1 37,-101.2l-19.7,-18.8c17.1,17.4 0.5,62.3 -37.3,100.8c-22.3,22.7 -47.1,38.2 -67.4,43.6c-8.5,2.3 -18.4,3.5 -25.8,2.9l-0.5,0l11.4,10.8c0.4,0.4 0.8,0.8 1.2,1.2l0,0zm-12.9,-20.9c22,2 56.1,-15.4 85.8,-45.7c38.1,-38.8 54.6,-84.1 36.9,-101.2c-16.4,-15.8 -57.6,-3.8 -93.8,29.4c19.8,6.6 36.7,20.2 48.8,36.6l-77.7,80.9z" />
            <path fill={color} d="m319.7,162.8l0,-105l37.2,0c37.3,0 56,17.1 56,51.2c0,16.4 -5.1,29.4 -15.3,39.2c-10.2,9.8 -23.7,14.6 -40.7,14.6l-37.2,0zm23.6,-85.8l0,66.6l11.7,0c10.3,0 18.3,-3.1 24.1,-9.2c5.8,-6.2 8.8,-14.5 8.8,-25.1c0,-10 -2.9,-17.9 -8.7,-23.6c-5.8,-5.7 -13.9,-8.6 -24.4,-8.6l-11.5,0l0,-0.1z" />
            <path fill={color} d="m475.4,108.7c-2.8,-1.5 -6,-2.3 -9.7,-2.3c-5,0 -9,1.8 -11.8,5.5c-2.8,3.7 -4.2,8.7 -4.2,15.1l0,35.8l-23.1,0l0,-75l23.1,0l0,13.9l0.3,0c3.7,-10.2 10.3,-15.2 19.8,-15.2c2.4,0 4.3,0.3 5.7,0.9l0,21.3l-0.1,0z" />
            <path fill={color} d="m556.4,162.8l-23.1,0l0,-11.4l-0.4,0c-5.7,8.8 -13.4,13.3 -22.9,13.3c-17.4,0 -26.1,-10.5 -26.1,-31.6l0,-45.3l23.1,0l0,43.2c0,10.6 4.2,15.9 12.6,15.9c4.1,0 7.5,-1.5 10,-4.4c2.5,-2.9 3.7,-6.8 3.7,-11.8l0,-42.9l23.1,0l0,75z" />
            <path fill={color} d="m647.9,154.2c0,13.9 -4,24.7 -12.1,32.3c-8.1,7.6 -19.7,11.5 -35,11.5c-10.1,0 -18.1,-1.4 -24,-4.3l0,-19.5c7.7,4.5 15.5,6.7 23.4,6.7c7.8,0 13.9,-2.1 18.2,-6.2c4.3,-4.1 6.4,-9.7 6.4,-16.8l0,-5.9l-0.3,0c-5.3,8.4 -13.1,12.6 -23.4,12.6c-9.6,0 -17.2,-3.4 -22.8,-10.1c-5.6,-6.7 -8.4,-15.8 -8.4,-27.1c0,-12.7 3.1,-22.8 9.4,-30.2c6.2,-7.5 14.5,-11.2 24.7,-11.2c9.1,0 16,3.5 20.5,10.5l0.3,0l0,-8.7l23.1,0l0,66.4zm-22.9,-27.4l0,-5.9c0,-4.7 -1.4,-8.8 -4.2,-12.1c-2.8,-3.3 -6.5,-5 -10.9,-5c-5.1,0 -9.2,2 -12.1,6c-2.9,4 -4.4,9.6 -4.4,16.9c0,6.3 1.4,11.2 4.2,14.8s6.6,5.5 11.4,5.5c4.8,0 8.7,-1.8 11.6,-5.5c3,-3.8 4.4,-8.7 4.4,-14.7z" />
            <path fill={color} d="m800.2,162.8l-25.8,0l-7.5,-23.4l-37.4,0l-7.4,23.4l-25.6,0l38.2,-105l28.1,0l37.4,105zm-38.6,-41.5l-11.3,-35.3c-0.8,-2.6 -1.4,-5.8 -1.8,-9.4l-0.6,0c-0.2,3.1 -0.9,6.1 -1.8,9.2l-11.4,35.6l26.9,0l0,-0.1z" />
            <path fill={color} d="m883.1,154.2c0,13.9 -4,24.7 -12.1,32.3c-8.1,7.6 -19.7,11.5 -35,11.5c-10.1,0 -18.1,-1.4 -24,-4.3l0,-19.5c7.7,4.5 15.5,6.7 23.4,6.7c7.8,0 13.9,-2.1 18.2,-6.2c4.3,-4.1 6.4,-9.7 6.4,-16.8l0,-5.9l-0.3,0c-5.3,8.4 -13.1,12.6 -23.4,12.6c-9.6,0 -17.2,-3.4 -22.8,-10.1c-5.6,-6.7 -8.4,-15.8 -8.4,-27.1c0,-12.7 3.1,-22.8 9.4,-30.2c6.2,-7.5 14.5,-11.2 24.7,-11.2c9.1,0 16,3.5 20.5,10.5l0.3,0l0,-8.7l23.1,0l0,66.4zm-22.8,-27.4l0,-5.9c0,-4.7 -1.4,-8.8 -4.2,-12.1c-2.8,-3.3 -6.5,-5 -10.9,-5c-5.1,0 -9.2,2 -12.1,6c-2.9,4 -4.4,9.6 -4.4,16.9c0,6.3 1.4,11.2 4.2,14.8s6.6,5.5 11.4,5.5c4.8,0 8.7,-1.8 11.6,-5.5c2.9,-3.8 4.4,-8.7 4.4,-14.7z" />
            <path fill={color} d="m968.3,131.9l-48.9,0c0.8,10.9 7.6,16.3 20.6,16.3c8.3,0 15.5,-2 21.8,-5.9l0,16.7c-6.9,3.7 -15.9,5.6 -27,5.6c-12.1,0 -21.5,-3.4 -28.2,-10.1c-6.7,-6.7 -10,-16.1 -10,-28.1c0,-12.5 3.6,-22.3 10.8,-29.6c7.1,-7.2 16,-10.8 26.6,-10.8c10.9,0 19.4,3.2 25.4,9.7c6,6.5 9,15.3 9,26.4l0,9.8l-0.1,0zm-21.4,-14.2c0,-10.7 -4.3,-16.1 -13,-16.1c-3.7,0 -6.9,1.5 -9.6,4.6c-2.7,3.1 -4.4,6.9 -4.9,11.5l27.5,0z" />
            <path fill={color} d="m1053,162.8l-23,0l0,-41.7c0,-11.6 -4.2,-17.4 -12.5,-17.4c-4,0 -7.3,1.5 -9.9,4.6c-2.6,3.1 -3.9,7 -3.9,11.7l0,42.8l-23.1,0l0,-75l23.1,0l0,11.9l0.3,0c5.5,-9.1 13.5,-13.7 24.1,-13.7c16.6,0 24.9,10.3 24.9,30.9l0,45.9z" />
            <path fill={color} d="m1126.7,160.1c-5.2,3 -12.8,4.5 -22.6,4.5c-11.5,0 -20.8,-3.5 -28,-10.5c-7.1,-7 -10.7,-16 -10.7,-27c0,-12.7 3.8,-22.8 11.5,-30.1c7.6,-7.3 17.9,-11 30.7,-11c8.8,0 15.2,1.2 19.2,3.5l0,19.6c-4.8,-3.6 -10.2,-5.4 -16.2,-5.4c-6.6,0 -11.9,1.9 -15.8,5.8c-3.9,3.9 -5.8,9.2 -5.8,16.1c0,6.6 1.9,11.9 5.6,15.6c3.7,3.8 8.8,5.7 15.3,5.7c5.8,0 11.4,-1.8 16.9,-5.4l0,18.6l-0.1,0z" />
            <path fill={color} d="m1211.8,87.8l-30.5,81c-7.3,19.5 -18.4,29.2 -33.1,29.2c-5.6,0 -10.2,-0.6 -13.8,-1.9l0,-18.5c3.1,1.8 6.4,2.7 10,2.7c6,0 10.1,-2.8 12.5,-8.4l4,-9.3l-30.5,-74.9l25.6,0l14,45.6c0.9,2.8 1.6,6.2 2.1,10l0.3,0c0.4,-2.8 1.2,-6.1 2.4,-9.9l14.1,-45.8l22.9,0l0,0.2z" />
            <path fill={color} d="m1321.8,162.8l-21.9,0l0,-10.8l-0.3,0c-5,8.4 -12.5,12.6 -22.3,12.6c-7.3,0 -13,-2.1 -17.2,-6.2c-4.2,-4.1 -6.3,-9.6 -6.3,-16.5c0,-14.5 8.6,-22.9 25.9,-25.2l20.4,-2.7c0,-8.2 -4.4,-12.3 -13.3,-12.3c-8.9,0 -17.4,2.7 -25.5,8l0,-17.4c3.2,-1.7 7.6,-3.1 13.2,-4.4c5.6,-1.3 10.7,-1.9 15.3,-1.9c21.4,0 32.1,10.7 32.1,32l0,44.8l-0.1,0zm-21.7,-30.5l0,-5.1l-13.6,1.8c-7.5,1 -11.3,4.4 -11.3,10.2c0,2.6 0.9,4.8 2.7,6.5c1.8,1.7 4.3,2.5 7.4,2.5c4.3,0 7.9,-1.5 10.6,-4.5c2.8,-3 4.2,-6.8 4.2,-11.4z" />
            <path fill={color} d="m1349,164.6c-3.8,0 -7,-1.2 -9.5,-3.5c-2.5,-2.3 -3.8,-5.2 -3.8,-8.6c0,-3.5 1.3,-6.4 3.9,-8.6c2.6,-2.2 5.9,-3.4 9.8,-3.4c4,0 7.2,1.1 9.7,3.4c2.5,2.3 3.8,5.1 3.8,8.6c0,3.6 -1.3,6.5 -3.8,8.8c-2.7,2.2 -6,3.3 -10.1,3.3z" />
            <path fill={color} d="m1372.9,160.8l0,-18.8c3.8,2.3 7.6,4 11.4,5.1c3.8,1.1 7.4,1.7 10.7,1.7c4.1,0 7.3,-0.6 9.7,-1.7c2.4,-1.1 3.6,-2.8 3.6,-5.1c0,-1.5 -0.5,-2.7 -1.6,-3.7c-1.1,-1 -2.5,-1.8 -4.1,-2.6c-1.7,-0.7 -3.5,-1.4 -5.5,-2c-2,-0.6 -3.9,-1.2 -5.8,-2c-3,-1.1 -5.6,-2.3 -7.9,-3.6c-2.3,-1.3 -4.2,-2.8 -5.7,-4.5c-1.5,-1.7 -2.7,-3.7 -3.5,-5.9c-0.8,-2.2 -1.2,-4.9 -1.2,-8c0,-4.2 0.9,-7.8 2.7,-10.8c1.8,-3 4.3,-5.5 7.3,-7.4c3.1,-1.9 6.5,-3.3 10.5,-4.2c3.9,-0.9 8,-1.4 12.3,-1.4c3.3,0 6.7,0.3 10.1,0.8c3.4,0.5 6.8,1.3 10.1,2.2l0,17.9c-2.9,-1.7 -6.1,-3 -9.4,-3.8c-3.3,-0.9 -6.6,-1.3 -9.9,-1.3c-1.5,0 -2.9,0.1 -4.3,0.4c-1.3,0.3 -2.5,0.7 -3.6,1.2s-1.8,1.2 -2.4,2s-0.9,1.7 -0.9,2.7c0,1.4 0.4,2.5 1.3,3.5c0.9,1 2,1.8 3.4,2.5c1.4,0.7 3,1.3 4.7,1.9c1.7,0.6 3.5,1.1 5.2,1.7c3.1,1.1 5.9,2.2 8.3,3.5c2.5,1.3 4.6,2.8 6.4,4.5c1.8,1.7 3.1,3.7 4.1,6c1,2.3 1.4,5 1.4,8.2c0,4.4 -1,8.2 -2.9,11.4c-1.9,3.1 -4.5,5.7 -7.7,7.7c-3.2,2 -6.9,3.4 -11.1,4.3c-4.2,0.9 -8.5,1.4 -13,1.4c-8,0 -15.7,-1.3 -22.7,-3.8z" />
            <path fill={color} d="m1452.6,164.6c-3.8,0 -7,-1.2 -9.5,-3.5c-2.5,-2.3 -3.8,-5.2 -3.8,-8.6c0,-3.5 1.3,-6.4 3.9,-8.6c2.6,-2.2 5.9,-3.4 9.8,-3.4c4,0 7.2,1.1 9.7,3.4c2.5,2.3 3.8,5.1 3.8,8.6c0,3.6 -1.3,6.5 -3.8,8.8c-2.6,2.2 -6,3.3 -10.1,3.3z" />
        </svg>
    );
};

export default LogoFooter;
