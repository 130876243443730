function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_HOME = '/'
const ROOTS_INTERACTIONS = '/kontrola-preskripce'
const ROOTS_ABOUT_INTERACTIONS = '/o-interakcich'
const ROOTS_ABOUT_DATABASE = '/o-databazi'
const ROOTS_ACCOUNT = '/ucet'


export const PATH_HOME = {
    root: ROOTS_HOME
}

export const PATH_AUTH = {
    root: ROOTS_HOME,
    login: path(ROOTS_HOME, 'prihlasit'),
    register: path(ROOTS_HOME, 'registrovat'),
    confirmEmail: path(ROOTS_HOME, 'potvrdit-email'),
    confirmEmail2: (token) => path(ROOTS_HOME, `potvrdit-email?token=${token}`),
    forgotPassword: path(ROOTS_HOME, 'zapomenute-heslo'),
    restorePassword: path(ROOTS_HOME, 'obnovit-heslo'),
    restorePassword2: (token) => path(ROOTS_HOME, `obnovit-heslo?token=${token}`)
}

export const PATH_INTERACTIONS = {
    root: ROOTS_INTERACTIONS,
    pli: (sukl) => path(ROOTS_INTERACTIONS, `/${sukl}`)
}

export const PATH_ABOUT_INTERACTIONS = {
    root: ROOTS_ABOUT_INTERACTIONS
}

export const PATH_ABOUT_DATABASE = {
    databaseCreation: path(ROOTS_ABOUT_DATABASE, '/tvorba-databaze'),
    evaluationOfInteractions: path(ROOTS_ABOUT_DATABASE, '/hodnoceni-interakci'),
    workWithDatabse: path(ROOTS_ABOUT_DATABASE, '/prace-s-databazi'),
    glossary: path(ROOTS_ABOUT_DATABASE, '/slovnik-pojmu'),
    licenseAgreement: path(ROOTS_ABOUT_DATABASE, '/licencni-ujednani'),
    termsAndConditions: path(ROOTS_ABOUT_DATABASE, '/obchodni-podminky'),
};

export const PATH_ACCOUNT = {
    root: ROOTS_ACCOUNT,
    extendLicense: ROOTS_ACCOUNT, //path(ROOTS_ACCOUNT, '/prodlouzit-licenci'),
    paymentStatus: ROOTS_ACCOUNT //path(ROOTS_ACCOUNT, '/stav-platby')
}
